import { useMemo } from 'react';
import { AutoPipCoveragesResponse } from '@aaa-ncnu-ie/ez-quote-api-coverages';
import {
  GetDocumentsResponse,
  GetDocumentsURLResponse,
} from '@aaa-ncnu-ie/ez-quote-api-documents';
import {
  MelissaExpressFreeFormResponse,
  ValidateEmailRequest,
  ValidateEmailResponse,
  ValidatePhoneNumberRequest,
  ValidatePhoneNumberResponse,
} from '@aaa-ncnu-ie/ez-quote-api-util';
import {
  AbtestRequest,
  AbTestResponse,
} from '@aaa-ncnu-ie/ez-quote-session-types';
import { getCookie, setCookie } from 'helpers/cookies';
import { noop } from 'lodash';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import wretch, { Wretcher } from 'wretch';
import { VehicleOwnership, VehicleUsageType } from 'components/forms/formTypes';
import { hasText } from '../helpers/functions';
import { getLocalStorageItem } from '../helpers/localStorage';
import {
  CurrentCarrierRequest,
  CurrentCarrierResponse,
  MetadataResponse,
} from './schema/carrier.schema';
import { GetCoveragesResponse } from './schema/coverages.schema';
import {
  GetAppliedDiscountsResponse,
  GetAvailableDiscountsResponse,
  GetDiscountsResponse,
  GetMembershipDiscountResponse,
  PatchDiscountsRequest,
  PatchMembershipDiscountRequest,
  SaveAutoDiscountsRequest,
  UbiResponse,
} from './schema/discounts.schema';
import {
  AddDriverResponse,
  GetAssignmentResponse,
  GetDriverIncidentsResponse,
  GetDriversResponse,
  GetDrivingHistoryResponse,
  GetIncidentMetadata,
  GetQuoteIncidentsResponse,
  MetadataResponse as DriverMetadataResponse,
  SaveDrivingHistoryRequest,
  SaveIncidentsRequest,
  UpdateDriverResponse,
} from './schema/driver.schema';
import {
  CanContinueResponse,
  InitData,
  RedirectResponse,
  RetrieveQuoteRequest,
  RetrieveQuotesResponse,
  SessionStatusRequest,
} from './schema/initialize.schema';
import {
  AddAdditionalInsuredRequest,
  Address,
  AutoPrefillFlags,
  ClubCodeResponse,
  CreatePrimaryInsuredResponse,
  GetAdditionalInsuredResponse,
  GetCountyTownshipResponse,
  GetPrimaryInsuredResponse,
  MetadataResponse as InsuredMetadataResponse,
  PolicyType,
  PropertyPrefillFlags,
  UpdatePrimaryInsuredRequest,
} from './schema/insured.schema';
import {
  IssueMembershipRequest,
  IssueMembershipResponse,
  MembershipOptInResponse,
  MembershipOptionsResponse,
  MembershipResponse,
  MembershipSummaryResponse,
  UpdateMembershipMemberSelectionRequest,
  UpdateMembershipNumberRequest,
  UpdateMembershipOptInRequest,
} from './schema/membership.schema';
import {
  ConfigResponse as PaymentConfigResponse,
  GetBankNameResponse,
  GetPcAuthTokenResponse,
  IssueQuoteRequest,
  IssueQuoteResponse,
  PaymentPlanRequest,
  PaymentPlansResponse,
} from './schema/payment.schema';
import {
  ClaimsRequestResponse,
  DwellingDetails,
  LandlordRequestResponse,
  MetadataResponse as PropertyMetadataResponse,
  MortgageDetails,
  OptionalAmenitiesRequest,
  OptionalAmenitiesResponse,
  PropertyDetails,
  PropertyMetadataRequest,
  RenovationsRequest,
  RenovationsResponse,
  UpdateUnderwritingQuestionsRequest,
} from './schema/property.schema';
import {
  EffectiveDatePatchRequest,
  MandatoryViewPolicyDocumentsResponse,
  Preferences,
  PreferencesResponse,
  PurchaseInitiatedRequest,
  QuoteExit,
  QuoteSummaryResponse,
  QuoteTypeRequest,
} from './schema/quote.schema';
import {
  AddVehicleResponse,
  GetAntitheftMetadata,
  GetLessorLienholdersResponse,
  GetVehiclesV2Response,
  ModelInfoList,
  VehicleAddress,
  VehicleMileageEstimation,
  VinInfo,
} from './schema/vehicle.schema';
import {
  ClubConfigResponse,
  ClubZipCodeValidationErrorResponse,
  WarningResponse,
} from './responseTypes';
import type { Delete, Get, Patch, Post, Put } from './types';

// todo: add better types here
export interface ActionCreators {
  initialize: Post<Record<string, unknown>, InitData>;
  adcInitialize: Post<Record<string, unknown>, RedirectResponse>;
  resume: Post<RetrieveQuoteRequest, RetrieveQuotesResponse>;
  renewSession: Get<Record<string, unknown>, any>;
  sessionStatus: Post<SessionStatusRequest, any>;
  canSessionContinue: Get<Record<string, unknown>, CanContinueResponse>;

  // club
  getClubInfo: Get<
    { source?: string; clubCd?: string; state?: string; promocode?: string },
    ClubConfigResponse
  >;

  // address
  getAddresses: Get<
    { melissaToken: string; searchTerm: string },
    MelissaExpressFreeFormResponse
  >;
  getAutocompleteAddresses: Get<
    { address: string },
    MelissaExpressFreeFormResponse
  >;
  validateClubZipCode: Get<
    Partial<{
      policyType: string;
      state: string;
      postalCode: string;
      source: string;
    }>,
    Partial<
      ClubCodeResponse & ClubZipCodeValidationErrorResponse & WarningResponse
    >
  >;
  getAbTest: Post<AbtestRequest, Partial<AbTestResponse>>;
  validateEmail: Post<ValidateEmailRequest, ValidateEmailResponse>;
  validatePhoneNumber: Post<
    ValidatePhoneNumberRequest,
    ValidatePhoneNumberResponse
  >;
  // vehicles; TODO: Add schema and update types
  getAllVehicles: Get<Record<string, unknown>, GetVehiclesV2Response>;
  getAllVehicleAddress: Get<Record<string, unknown>, VehicleAddress>;
  updateVehicleAddress: Patch<Record<string, unknown>, Record<string, unknown>>;
  updateThirdPartyAddress: Patch<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createVehicle: Post<Record<string, unknown>, AddVehicleResponse>;
  updateVehicle: Patch<Record<string, unknown>, Record<string, unknown>>;
  deleteVehicle: Delete<Record<string, unknown>, string>;
  getVehicleByVin: Get<Record<string, unknown>, VinInfo>;
  getVehicleMakes: Get<{ state: string; year: string }, string[]>;
  getVehicleModels: Get<
    { state: string; year: string; make: string },
    ModelInfoList[]
  >;
  selectVehicles: Put<
    Record<string, unknown> | string[],
    Record<string, unknown>
  >;
  getVehicleMileage: Get<
    {
      milesToWork: string;
      vehicleId: string;
      autoQuoteSource: string;
      vehicleUsage: VehicleUsageType;
    },
    VehicleMileageEstimation
  >;
  getVehicleLienholders: Get<
    {
      ownership: VehicleOwnership;
    },
    GetLessorLienholdersResponse
  >;
  getAntitheftMetadata: Get<Record<string, unknown>, GetAntitheftMetadata>;
  updateBusinessUsage: Patch<Record<string, unknown>, Record<string, unknown>>;
  updateVehicleVin: Patch<Record<string, unknown>, Record<string, unknown>>;
  // drivers
  getAllDrivers: Get<Record<string, unknown>, GetDriversResponse>;
  createDriver: Post<
    { firstName: string; lastName: string },
    AddDriverResponse
  >;
  updateDriver: Patch<Record<string, unknown>, UpdateDriverResponse>;
  selectDrivers: Put<
    Record<string, unknown> | string[],
    Record<string, unknown>
  >;
  getDrivingHistory: Get<
    { driverId: string | undefined },
    GetDrivingHistoryResponse
  >;
  updateDrivingHistory: Put<
    Record<string, unknown> | string[],
    SaveDrivingHistoryRequest
  >;
  getDriverMetadata: Get<
    { state: string; policyType: string },
    DriverMetadataResponse
  >;
  getDriverAssignment: Get<Record<string, unknown>, GetAssignmentResponse>;
  updateDriverAssignment: Put<Record<string, unknown>, undefined>;
  getIncidents: Get<{ driverId: string }, GetDriverIncidentsResponse>;
  getAllIncidents: Get<Record<string, unknown>, GetQuoteIncidentsResponse>;
  getIncidentsMetadata: Get<Record<string, unknown>, GetIncidentMetadata>;
  updateIncidents: Put<Record<string, unknown>, SaveIncidentsRequest>;
  //property
  getProperty: Get<Record<string, unknown>, PropertyDetails>;
  getPropertyMetadata: Get<PropertyMetadataRequest, PropertyMetadataResponse>;
  updateProperty: Patch<PropertyDetails, Record<string, string>>;
  updateUnderwritingQuestions: Patch<
    UpdateUnderwritingQuestionsRequest,
    undefined
  >;
  updateDwellingDetails: Patch<DwellingDetails, DwellingDetails>;
  getDwellingDetails: Get<Record<string, unknown>, DwellingDetails>;
  getClaimsInfo: Get<Record<string, unknown>, ClaimsRequestResponse>;
  updateClaimsInfo: Patch<ClaimsRequestResponse, undefined>;
  getLandlordDetails: Get<Record<string, unknown>, LandlordRequestResponse>;
  updateLandlordDetails: Put<LandlordRequestResponse, unknown>;
  getPropertyOptionalAmenities: Get<
    Record<string, unknown>,
    OptionalAmenitiesResponse
  >;
  updatePropertyOptionalAmenities: Patch<
    OptionalAmenitiesRequest,
    OptionalAmenitiesResponse
  >;
  getMortgageDetails: Get<Record<string, unknown>, MortgageDetails>;
  updateMortgageDetails: Patch<MortgageDetails, MortgageDetails>;
  getRenovations: Get<Record<string, unknown>, RenovationsResponse>;
  updateRenovations: Patch<RenovationsRequest, RenovationsResponse>;
  // insured
  getInsured: Get<
    Partial<{ policyType: PolicyType }>,
    Partial<GetPrimaryInsuredResponse & AutoPrefillFlags & PropertyPrefillFlags>
  >;
  getClubCode: Get<
    Partial<{
      policyType: PolicyType;
      state: string;
      postalCode: string;
      source: string;
    }>,
    ClubCodeResponse
  >;
  createInsured: Post<Record<string, unknown>, CreatePrimaryInsuredResponse>;
  updateInsured: Patch<UpdatePrimaryInsuredRequest, unknown>;
  updateMailingAddress: Patch<Address, Record<string, unknown>>;
  getInsuredMetadata: Get<
    { state: string; policyType: string },
    InsuredMetadataResponse
  >;
  getCountyTownship: Get<{ postalCode: string }, GetCountyTownshipResponse>;
  getAdditionalInsured: Get<
    Record<string, unknown>,
    GetAdditionalInsuredResponse
  >;
  updateAdditionalInsured: Put<AddAdditionalInsuredRequest, unknown>;
  //discounts
  getDiscounts: Get<Record<string, unknown>, GetAvailableDiscountsResponse>;
  updateDiscounts: Patch<Record<string, unknown>, SaveAutoDiscountsRequest>;
  getAppliedDiscounts: Get<
    Record<string, unknown>,
    GetAppliedDiscountsResponse
  >;
  getDiscountsV1: Get<Record<string, unknown>, GetDiscountsResponse>;
  updateDiscountsV2: Patch<PatchDiscountsRequest, undefined>;
  getUbiReport: Get<Record<string, unknown>, UbiResponse>;
  getMembershipDiscount: Get<
    Record<string, unknown>,
    GetMembershipDiscountResponse
  >;
  updateMembershipDiscount: Patch<
    PatchMembershipDiscountRequest,
    GetMembershipDiscountResponse
  >;
  // current-carrier
  getCurrentCarrierMetadata: Get<
    { state: string; policyType: string },
    MetadataResponse
  >;
  getCurrentCarrier: Get<{ policyType: string }, CurrentCarrierResponse>;
  updateCurrentCarrier: Patch<Record<string, unknown>, CurrentCarrierRequest>;

  // quote
  quoteExit: Post<QuoteExit, Record<string, unknown>>;
  quoteSummary: Get<Record<string, unknown>, QuoteSummaryResponse>;
  updateQuoteType: Patch<QuoteTypeRequest, unknown>;
  updatePreferences: Patch<Preferences, PreferencesResponse>;
  updatePurchaseInitiated: Patch<PurchaseInitiatedRequest, unknown>;
  getMandatoryViewPolicyDocuments: Get<
    Record<string, unknown>,
    MandatoryViewPolicyDocumentsResponse
  >;

  //coverages
  getCoverages: Get<Record<string, unknown>, GetCoveragesResponse>;
  updateCoverages: Post<
    { body: Record<string, unknown>; isBindFlow: boolean },
    GetCoveragesResponse
  >;
  getPipCoverages: Get<Record<string, unknown>, AutoPipCoveragesResponse>;
  updatePipCoverages: Patch<Record<string, unknown>, Record<string, unknown>>;
  keepAlive: Get<Record<string, unknown>, Record<string, unknown>>;

  //events
  sendTrackEvent: Post<Record<string, unknown>, void>;
  sendPageEvent: Post<Record<string, unknown>, void>;

  //effective Date
  updateEffectiveDate: Patch<EffectiveDatePatchRequest, undefined>;

  //billing
  getPaymentPlans: Get<Record<string, unknown>, PaymentPlansResponse>;
  updatePaymentPlan: Patch<PaymentPlanRequest, PaymentPlansResponse>;
  getPcAauthToken: Get<Record<string, unknown>, GetPcAuthTokenResponse>;
  getBankName: Get<{ routingNumber: string }, GetBankNameResponse>;
  issueQuote: Post<IssueQuoteRequest, IssueQuoteResponse>;
  initIssueQuote: Post<never, void>;
  getPaymentConfig: Get<Record<string, unknown>, PaymentConfigResponse>;

  //documents
  getDocuments: Get<Record<string, unknown>, GetDocumentsResponse>;
  getEoiDocuments: Get<Record<string, unknown>, GetDocumentsURLResponse>;

  // membership
  getMembership: Get<Record<string, unknown>, MembershipResponse>;
  updateMembershipNumber: Patch<
    UpdateMembershipNumberRequest,
    MembershipResponse
  >;
  getMembershipOptions: Get<Record<string, unknown>, MembershipOptionsResponse>;
  updateMembershipOptIn: Patch<
    UpdateMembershipOptInRequest,
    MembershipOptInResponse
  >;
  updateMembershipMembersSelection: Patch<
    UpdateMembershipMemberSelectionRequest,
    MembershipOptionsResponse
  >;
  getMembershipOptIn: Get<Record<string, unknown>, MembershipOptInResponse>;
  getMembershipSummary: Get<Record<string, unknown>, MembershipSummaryResponse>;
  issueMembership: Post<IssueMembershipRequest, IssueMembershipResponse>;
}

const cookies = new Cookies();

const initialize = `${
  import.meta.env.VITE_INITIALIZE_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-initialize/v1`;

const adcInitialize = `${
  import.meta.env.VITE_INITIALIZE_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-initialize/v1/redirect`;

const initializeV2 = `${
  import.meta.env.VITE_INITIALIZE_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-initialize/v2`;

const address = `https://expressentry.melissadata.net/web/ExpressFreeForm?format=JSON&maxrecords=5&country=US&cols=county`;

const drivers = `${
  import.meta.env.VITE_DRIVERS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-driver/v1`;

const vehicles = `${
  import.meta.env.VITE_VEHICLES_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-vehicle/v1`;

const vehiclesV2 = `${
  import.meta.env.VITE_VEHICLES_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-vehicle/v2`;

const property = `${
  import.meta.env.VITE_PROPERTY_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-property/v1`;

const insured = `${
  import.meta.env.VITE_INSURED_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-insured/v1`;

const carrier = `${
  import.meta.env.VITE_CARRIER_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-current-carrier/v1`;

const discounts = `${
  import.meta.env.VITE_DISCOUNTS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-discounts/v1`;

const discountsV2 = `${
  import.meta.env.VITE_DISCOUNTS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-discounts/v2`;

// For some reason ?? does not work here.
const quote = `${
  import.meta.env.VITE_QUOTE_URL || import.meta.env.VITE_DEFAULT_API_URL
}/api-quote/v1`;

const coverages = `${
  import.meta.env.VITE_COVERAGES_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-coverages/v1`;

const events = `${
  import.meta.env.VITE_EVENTS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-events/v1`;

const billing = `${
  import.meta.env.VITE_PAYMENTS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-billing/v1`;

const documents = `${
  import.meta.env.VITE_DOCUMENTS_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-documents/v1`;

const membership = `${
  import.meta.env.VITE_MEMBERSHIP_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-aaa-membership/v1`;

const util = `${
  import.meta.env.VITE_UTIL_URL ?? import.meta.env.VITE_DEFAULT_API_URL
}/api-util/v1`;

const decorate = (gateway: Wretcher) => {
  const token = getCookie('token');
  return gateway.headers({
    ['X-Correlation-Id']: uuidv4(),
    ...(token && { ['Authorization']: token }),
  });
};

const initializeDecorate = (gateway: Wretcher) => {
  const previousSessionId = getLocalStorageItem('myq_previous_session_id');
  const token = getCookie('token');
  return gateway.headers({
    ['X-Correlation-Id']: uuidv4(),
    ...(token && { ['Authorization']: token }),
    ...(hasText(previousSessionId) && {
      ['X-Analytics-Previous-Id']: previousSessionId,
    }),
  });
};

const segmentDecorate = (gateway: Wretcher) => {
  const token = getCookie('token');
  return gateway.headers({
    ['X-Correlation-Id']: uuidv4(),
    ['Session-Id']: cookies.get('sessionId'),
    ...(token && { ['Authorization']: token }),
    ['X-Analytics-Url']: window.location.href,
    ['X-Analytics-Title']: document.title,
    ['X-Analytics-Timezone']: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const adcDecorate = (gateway: Wretcher) => {
  const token = getCookie('token_adc');
  return gateway.headers({
    ['X-Correlation-Id']: uuidv4(),
    ...(token && { ['Authorization']: token }),
  });
};

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  initialize: (body) =>
    initializeDecorate(gateway)
      .url(initialize)
      .middlewares([
        (next) => (url, opts) => {
          return next(url, opts).then((res) => {
            const token = res?.headers.get('authorizationToken');
            token && setCookie('token', token);
            return res;
          });
        },
      ])
      .post(body)
      .json(),

  adcInitialize: (body) =>
    adcDecorate(gateway).url(adcInitialize).post(body).json(),

  sessionStatus: (body) => {
    if (!cookies.get('sessionId')) return Promise.resolve();
    return segmentDecorate(gateway)
      .url(`${initialize}/session-status`)
      .patch(body);
  },

  canSessionContinue: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${initialize}/can-session-continue`)
      .get()
      .json(),

  renewSession: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${initialize}/renew-session`)
      .middlewares([
        (next) => (url, opts) => {
          return next(url, opts).then((res) => {
            const token = res?.headers.get('authorizationToken');
            token && setCookie('token', token);
            return res;
          });
        },
      ])
      .get()
      .json()
      .catch((e) => JSON.parse(e?.message))
      .catch(noop), // In case error message parsing fails

  resume: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${initializeV2}/resume`)
      .post(body)
      .json(),

  // club
  getClubInfo: (queryParams = {}, sessionId?) =>
    decorate(
      gateway.headers({ 'Session-Id': sessionId || cookies.get('sessionId') }),
    )
      .query(queryParams)
      .url(`${initialize}/config-for-club`)
      .get()
      .json(),

  // address
  getAddresses: (
    { melissaToken, searchTerm } = { melissaToken: '', searchTerm: '' },
  ) =>
    wretch().url(`${address}&id=${melissaToken}&ff=${searchTerm}`).get().json(),
  getAutocompleteAddresses: (queryParams = { address: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${util}/address/autocomplete`)
      .get()
      .json(),
  getAbTest: (body: AbtestRequest, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${util}/abtest`)
      .post(body)
      .json(),
  validateEmail: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${util}/validate-email`)
      .post(body)
      .json(),
  validatePhoneNumber: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${util}/validate-phone`)
      .post(body)
      .json(),
  validateClubZipCode: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/clubcode`)
      .get()
      .json()
      // Moved catch logic here in order for the type inference to work as expected
      .catch((e) => JSON.parse(e?.message))
      .catch(noop), // In case error message parsing fails

  // vehicles
  getAllVehicles: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(vehiclesV2).get().json(),
  getAllVehicleAddress: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/address`)
      .get()
      .json(),
  createVehicle: (body, queryParams = {}) =>
    decorate(gateway).query(queryParams).url(vehicles).post(body).json(),
  updateVehicle: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/${id}`)
      .patch(body)
      .json(),
  updateVehicleAddress: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/garaging-address`)
      .patch(body)
      .json(),
  updateThirdPartyAddress: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/lease-finance`)
      .patch(body)
      .json(),
  deleteVehicle: (id, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/${id}`)
      .delete()
      .json(),
  getVehicleMakes: (queryParams = { state: '', year: '' }) =>
    decorate(gateway).query(queryParams).url(`${vehicles}/makes`).get().json(),
  getVehicleModels: (queryParams = { year: '', make: '', state: '' }) =>
    decorate(gateway).query(queryParams).url(`${vehicles}/models`).get().json(),
  getVehicleByVin: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${vehicles}/vin`).get().json(),
  getVehicleLienholders: (
    queryParams = { ownership: VehicleOwnership.Financed },
  ) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/lessor-lienholder`)
      .get()
      .json(),
  getVehicleMileage: (
    queryParams = {
      milesToWork: '',
      vehicleId: '',
      autoQuoteSource: 'EZQuote',
      vehicleUsage: VehicleUsageType.Pleasure,
    },
  ) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/mileage`)
      .get()
      .json(),
  selectVehicles: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/select-vehicles`)
      .put(body)
      .json(),
  getAntitheftMetadata: (queryParams = { state: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/antitheft/metadata`)
      .get()
      .json(),
  updateBusinessUsage: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${vehicles}/business-usage`)
      .patch(body.vehiclesToUpdate)
      .json(),
  updateVehicleVin: (id, body) => {
    return decorate(gateway)
      .url(`${vehicles}/vin/update`)
      .patch(body.vehicleVinList)
      .json();
  },
  // drivers
  getAllDrivers: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(drivers).get().json(),
  createDriver: (
    body: { firstName: string; lastName: string },
    queryParams = {},
  ) => decorate(gateway).query(queryParams).url(drivers).post(body).json(),
  updateDriver: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${drivers}/${id}`)
      .patch(body)
      .json(),
  selectDrivers: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${drivers}/select-drivers`)
      .put(body)
      .json(),
  getDrivingHistory: (queryParams = { driverId: '' }) =>
    decorate(gateway)
      .url(`${drivers}/driving-history/${queryParams.driverId}`)
      .get()
      .json(),
  updateDrivingHistory: (body, queryParams = { driverId: '' }) =>
    decorate(gateway)
      .url(`${drivers}/driving-history/${queryParams.driverId}`)
      .put(body)
      .json(),
  getDriverMetadata: (queryParams = { state: '', policyType: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${drivers}/metadata`)
      .get()
      .json(),
  getDriverAssignment: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${drivers}/assignment`)
      .get()
      .json(),
  updateDriverAssignment: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${drivers}/assignment`)
      .put(body)
      .json(),
  getIncidents: (queryParams = { driverId: '' }) =>
    decorate(gateway)
      .url(`${drivers}/incidents/${queryParams.driverId}`)
      .get()
      .json(),
  getAllIncidents: () =>
    decorate(gateway).url(`${drivers}/incidents`).get().json(),
  getIncidentsMetadata: () =>
    decorate(gateway).url(`${drivers}/incidents/metadata`).get().json(),
  updateIncidents: (body, queryParams = { driverId: '' }) =>
    decorate(gateway)
      .url(`${drivers}/incidents/${queryParams.driverId}`)
      .put(body)
      .json(),

  // property
  getProperty: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(property).get().json(),
  getPropertyMetadata: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/metadata`)
      .get()
      .json(),
  updateProperty: (_, body, queryParams = {}) =>
    decorate(gateway).query(queryParams).url(property).patch(body).json(),
  updateUnderwritingQuestions: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/underwriting-question`)
      .patch(body)
      .json(),
  updateDwellingDetails: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/dwelling-details`)
      .patch(body)
      .json(),
  getDwellingDetails: (_, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/dwelling-details`)
      .get()
      .json(),
  getClaimsInfo: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${property}/claims`).get().json(),
  updateClaimsInfo: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/claims`)
      .patch(body)
      .json(),
  getLandlordDetails: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/landlord`)
      .get()
      .json(),
  updateLandlordDetails: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/landlord`)
      .put(body)
      .json(),
  getPropertyOptionalAmenities: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/optional-amenities`)
      .get()
      .json(),
  updatePropertyOptionalAmenities: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/optional-amenities`)
      .patch(body)
      .json(),
  getMortgageDetails: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/mortgage-details`)
      .get()
      .json(),
  updateMortgageDetails: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/mortgage-details`)
      .patch(body)
      .json(),
  getRenovations: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/renovations`)
      .get()
      .json(),
  updateRenovations: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${property}/renovations`)
      .patch(body)
      .json(),

  // insured
  getInsured: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/primary-insured`)
      .get()
      .json(),
  getClubCode: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/clubcode`)
      .get()
      .json(),
  createInsured: (body) =>
    decorate(gateway)
      .query({})
      .url(`${insured}/primary-insured`)
      .post(body)
      .json() as unknown as Promise<CreatePrimaryInsuredResponse>,
  updateInsured: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/primary-insured`)
      .patch(body)
      .json(),
  updateMailingAddress: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/address`)
      .patch(body)
      .json(),
  getInsuredMetadata: (queryParams = { state: '', policyType: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/metadata`)
      .get()
      .json(),
  getCountyTownship: (queryParams = { postalCode: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/county-township`)
      .get()
      .json(),
  getAdditionalInsured: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/additional-insured`)
      .get()
      .json(),
  updateAdditionalInsured: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${insured}/additional-insured`)
      .put(body)
      .json(),
  // Quote
  quoteExit: (body) =>
    decorate(gateway).query({}).url(`${quote}/exit`).post(body).json(),
  quoteSummary: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${quote}/summary`).get().json(),
  updateQuoteType: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${quote}/quote-type`)
      .patch(body)
      .json(),
  updatePreferences: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${quote}/preferences`)
      .patch(body)
      .json(),
  updatePurchaseInitiated: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${quote}/purchase-initiated`)
      .patch(body)
      .json(),
  getMandatoryViewPolicyDocuments: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${quote}/mandatory-view-policy-documents`)
      .get()
      .json(),

  // discounts
  getDiscounts: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${discounts}/available-discounts`)
      .get()
      .json(),
  updateDiscounts: (id, body, queryParams = {}) =>
    decorate(gateway).query(queryParams).url(discounts).patch(body).json(),
  getAppliedDiscounts: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${discounts}/applied-discounts`)
      .get()
      .json(),
  getDiscountsV1: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${discounts}`).get().json(),
  updateDiscountsV2: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${discountsV2}`)
      .patch(body)
      .json(),
  getUbiReport: (id, queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${discounts}/ubi`).get().json(),
  getMembershipDiscount: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${discounts}/membership-discount`)
      .get()
      .json(),
  updateMembershipDiscount: (_, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${discounts}/membership-discount`)
      .patch(body)
      .json(),
  //current-carrier
  getCurrentCarrierMetadata: (queryParams = { state: '', policyType: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${carrier}/metadata`)
      .get()
      .json(),
  getCurrentCarrier: (queryParams = { policyType: '' }) =>
    decorate(gateway).query(queryParams).url(carrier).get().json(),
  updateCurrentCarrier: (id, body, queryParams = {}) =>
    decorate(gateway).query(queryParams).url(carrier).patch(body).json(),

  //coverages
  getCoverages: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(coverages).get().json(),
  updateCoverages: ({ body, isBindFlow }) =>
    decorate(gateway)
      .query({ purchase: isBindFlow })
      .url(coverages)
      .post(body)
      .json(),
  getPipCoverages: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${coverages}/pip`).get().json(),
  updatePipCoverages: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${coverages}/pip`)
      .patch(body)
      .json(),
  keepAlive: (queryParams = { activity: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${coverages}/keep-alive`)
      .get()
      .json(),

  //events
  sendTrackEvent: (body, queryParams = {}) =>
    segmentDecorate(gateway)
      .query(queryParams)
      .url(`${events}/track`)
      .post(body)
      .json(),

  sendPageEvent: (body, queryParams = {}) =>
    segmentDecorate(gateway)
      .query(queryParams)
      .url(`${events}/page`)
      .post(body)
      .json(),

  updateEffectiveDate: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${quote}/effective-date`)
      .patch(body)
      .json(),

  //billings
  getPaymentPlans: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/pay-plans`)
      .get()
      .json(),
  updatePaymentPlan: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/pay-plan`)
      .patch(body)
      .json(),
  getPcAauthToken: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/pc-auth-token`)
      .get()
      .json(),
  getBankName: (queryParams = { routingNumber: '' }) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/bank-name`)
      .get()
      .json(),
  issueQuote: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/issue-quote`)
      .post(body)
      .json(),
  initIssueQuote: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${billing}/init-issue-quote`)
      .post()
      .json(),
  getPaymentConfig: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${billing}/config`).get().json(),

  //documents
  getDocuments: (queryParams = {}) =>
    decorate(gateway).query(queryParams).url(`${documents}`).get().json(),
  getEoiDocuments: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${documents}/evidence-insurance`)
      .get()
      .json(),

  //membership
  getMembership: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership`)
      .get()
      .json(),
  updateMembershipNumber: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-number`)
      .patch(body)
      .json(),
  getMembershipOptions: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-options`)
      .get()
      .json(),
  updateMembershipOptIn: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-opt-in`)
      .patch(body)
      .json(),
  updateMembershipMembersSelection: (id, body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-member-selection`)
      .patch(body)
      .json(),
  getMembershipOptIn: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-opt-in`)
      .get()
      .json(),
  getMembershipSummary: (queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/membership-summary`)
      .get()
      .json(),
  issueMembership: (body, queryParams = {}) =>
    decorate(gateway)
      .query(queryParams)
      .url(`${membership}/issue-membership`)
      .post(body)
      .json(),
});

const getHeaders = (sessionId: string) => {
  return {
    'Session-Id': sessionId,
    'X-Api-Key': import.meta.env.VITE_API_KEY || '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Locale: 'en-US',
  };
};

// This hook can be completely removed in the future,
// in favor of the "decorate" method
// I keep it for compatibility
export const useActionCreators = () => {
  const sessionIdFromCookie = cookies.get('sessionId');

  const [actionCreators, headers] = useMemo(() => {
    const headers = getHeaders(sessionIdFromCookie);
    const newGateway = wretch().headers(headers);
    return [getActionCreators(newGateway), headers];
  }, [sessionIdFromCookie]);

  return { actionCreators, headers };
};
